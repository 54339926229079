import React from 'react';

import { PrimaryButton } from '../../Buttons/PrimaryButton';
import { IInputOnChange, Input } from '../../Inputs/Input';

import style from './SignUp.scss';

import cn from 'classnames';
import { isEmail } from 'utils/helpers';

interface ISignUpFormProps {
  className?: string;
  btnText?: string;
  children?: React.ReactNode;
  callBack?: () => void;
  tags?: string[];
}

interface ISignUpFormState {
  email: string;
  errorMsg: string;
  isSending: boolean;
  isSubscribed: boolean;
}

export const SUBSCRIBED_COOKIE_NAME = 'tsr_subscribed_at';

const isSubscribed =
  typeof document === 'undefined' ? false : document.cookie.includes(SUBSCRIBED_COOKIE_NAME);

class SignUpForm extends React.Component<ISignUpFormProps, ISignUpFormState> {
  constructor(props: ISignUpFormProps) {
    super(props);
    this.state = {
      email: '',
      errorMsg: '',
      isSending: false,
      isSubscribed,
    };
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, isSending } = this.state;

    if (isSending) {
      return;
    }

    // Validate email address
    if (!isEmail(email)) {
      this.setState({ errorMsg: 'Invalid email address.' });
      return;
    }

    this.setState({ isSending: true });

    const tags = ['SignUp', 'TSR', window.location.pathname];

    if (this.props.tags) {
      tags.push(...this.props.tags);
    }

    // Send payload
    fetch('https://p5erhrmlx5.execute-api.us-east-2.amazonaws.com/prod/TSRForms', {
      body: JSON.stringify({
        action: 'SUBSCRIBE',
        email,
        referrer: document.referrer,
        tags,
      }),
      method: 'POST',
    })
      .then((res) => {
        this.setState({ isSubscribed: true, isSending: false, email: '' });
        document.cookie = `${SUBSCRIBED_COOKIE_NAME}=${new Date().getTime()};path=/`;
        if (this.props.callBack) {
          this.props.callBack();
        }
      })
      .catch((err) => {
        this.setState({ errorMsg: err.message, isSending: false });
      });
  };

  handleInputChange = (input: IInputOnChange) => {
    this.setState({ email: input.value, errorMsg: '' });
  };

  render() {
    const { className, btnText, children } = this.props;
    const { email, errorMsg, isSending } = this.state;

    return this.state.isSubscribed && children ? (
      children
    ) : (
      <form className={cn(className, style.form)} onSubmit={this.handleSubmit}>
        <Input
          className={style.input}
          value={email}
          name="email"
          onChange={this.handleInputChange}
          placeholder="Email"
          helperText={errorMsg}
          hasError={errorMsg !== ''}
        />
        <PrimaryButton type="submit" isLoading={isSending}>
          {btnText || 'Subscribe'}
        </PrimaryButton>
      </form>
    );
  }
}

export default SignUpForm;
