import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const SaveMoneyIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3335 10.9999C19.7491 9.35299 18.7365 7.74608 17.2239 6.87085L17.2227 3.2229L12.723 5.88944H8.66762C7.18228 5.8892 5.73188 6.34004 4.50842 7.18229C3.28495 8.02455 2.3461 9.21849 1.81614 10.6061C1.28617 11.9937 1.19008 13.5095 1.54059 14.9529C1.8911 16.3964 2.67167 17.6993 3.77899 18.6893L4.3335 22.9999H7.3335L8.66762 20.5556H14.5562L15.3335 22.9999H18.3335V18.9999C19.2513 18.1774 19.9229 17.161 20.3335 15.9999H22.3335V10.9999H20.3335Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        opacity="0.5"
        d="M3.99176 7.57365C3.45572 7.16165 3.04403 6.60947 2.80218 5.97813C2.56034 5.34678 2.49777 4.66086 2.62137 3.99618C2.74498 3.33149 3.04995 2.71393 3.50259 2.21174C3.95523 1.70954 4.5379 1.34228 5.18621 1.15054C5.83452 0.958804 6.52322 0.950052 7.1762 1.12526C7.82917 1.30046 8.42099 1.6528 8.88624 2.14333C9.35149 2.63386 9.67206 3.24347 9.81251 3.9048C9.95297 4.56613 9.90784 5.25342 9.68212 5.8907"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M16.0017 10.7783V10.7905"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(SaveMoneyIcon);
