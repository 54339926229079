import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const DebtIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33342 12.0589L6.00008 5.35303L2.66675 12.0589C2.66675 12.9482 3.01794 13.801 3.64306 14.4298C4.26818 15.0586 5.11603 15.4118 6.00008 15.4118C6.88414 15.4118 7.73198 15.0586 8.3571 14.4298C8.98223 13.801 9.33342 12.9482 9.33342 12.0589Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M22.6667 12.0589L19.3334 5.35303L16.0001 12.0589C16.0001 12.9482 16.3513 13.801 16.9764 14.4298C17.6015 15.0586 18.4494 15.4118 19.3334 15.4118C20.2175 15.4118 21.0653 15.0586 21.6904 14.4298C22.3156 13.801 22.6667 12.9482 22.6667 12.0589Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M7.11111 20H6.11111V22H7.11111V20ZM18.2222 22H19.2222V20H18.2222V22ZM5.83466 4.3667C5.28998 4.45802 4.92245 4.9736 5.01376 5.51828C5.10508 6.06296 5.62066 6.43049 6.16534 6.33918L5.83466 4.3667ZM12.6667 4.23529L12.832 3.24906L12.6667 3.22134L12.5013 3.24906L12.6667 4.23529ZM19.168 6.33918C19.7127 6.43049 20.2283 6.06296 20.3196 5.51828C20.4109 4.9736 20.0434 4.45802 19.4987 4.3667L19.168 6.33918ZM13.6667 2V1H11.6667V2H13.6667ZM11.6667 21V22H13.6667V21H11.6667ZM7.11111 22H18.2222V20H7.11111V22ZM6.16534 6.33918L12.832 5.22153L12.5013 3.24906L5.83466 4.3667L6.16534 6.33918ZM12.5013 5.22153L19.168 6.33918L19.4987 4.3667L12.832 3.24906L12.5013 5.22153ZM11.6667 2V21H13.6667V2H11.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default React.memo(DebtIcon);
