import React from 'react';

import style from './Button.scss';

import cn from 'classnames';

interface IButtonProps {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export const Button = (props: IButtonProps) => {
  const {
    className,
    children,
    onClick,
    isLoading = false,
    isDisabled = false,
    type = 'button',
    ...restProps
  } = props;

  function handleClick() {
    if (typeof onClick === 'function' && !isLoading && !isDisabled) {
      onClick();
    }
  }

  return (
    <button
      type={type}
      className={cn(className, style.wrapper)}
      onClick={handleClick}
      {...restProps}
    >
      {children}
    </button>
  );
};
