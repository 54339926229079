import React from 'react';

import { list } from '../HomePage/Heros/A/Hero';

import style from './ExploreNewHorizons.scss';

const ExploreNewHorizons = () => {
  return (
    <section className={style.exploreNewHorizons}>
      <div className="container">
        <h6>Explore New Horizons</h6>
        <div className={style.items}>
          {list.map((item) => {
            return item.subLinks ? (
              <div className={style.hasSubLinks}>
                {item.icon}
                <h4>{item.title}</h4>
                <div className={style.subItems}>
                  {item.subLinks.map((subItem) => (
                    <a
                      key={subItem.href + subItem.title}
                      href={subItem.href}
                      target={subItem.target}
                    >
                      {subItem.title}
                    </a>
                  ))}
                </div>
              </div>
            ) : (
              <a key={item.href + item.title} href={item.href} target={item.target}>
                {item.icon}
                <h4>{item.title}</h4>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ExploreNewHorizons;
