export const lazyLoading = () => {
  [].slice
    .call(document.querySelectorAll('script[data-src]'))
    .forEach((script: HTMLScriptElement) => {
      const readyStateCheckInterval = setInterval(() => {
        if (document.readyState === 'complete') {
          setTimeout(() => {
            script.src = script.src || script.dataset.src || '';
            clearInterval(readyStateCheckInterval);
          }, 3000);
        }
      }, 500);
    });

  const elList = [].slice.call(
    // iframe[data-src]'
    document.querySelectorAll('img.lazy, img[data-src]')
  );

  if ('IntersectionObserver' in window) {
    const lazyElObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const el: any = entry.target;

            const dataset = el.dataset;

            if (dataset.src) {
              el.src = dataset.src;
            }

            if (dataset.srcset) {
              el.srcset = dataset.srcset;
            }

            el.style.filter = 'blur(8px)';

            el.onload = () => {
              el.style.transition = '300ms';
              setTimeout(() => {
                el.style.filter = 'blur(0px)';
              }, 300);
            };

            el.classList.remove('lazy');
            lazyElObserver.unobserve(el);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    elList.forEach((el: HTMLImageElement | HTMLIFrameElement) => {
      lazyElObserver.observe(el);
    });
  } else {
    elList.forEach((el: any) => {
      el.src = el.src || el.dataset.src || '';
      el.srcset = el.srcset || el.dataset.srcset || '';
      el.classList.remove('lazy');
    });
  }
};
