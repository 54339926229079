import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const LoansIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.5"
        cx="12"
        cy="12.0005"
        r="2"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M2.5 9V9C3.89782 9 4.59674 9 5.14805 8.77164C5.88313 8.46716 6.46716 7.88313 6.77164 7.14805C7 6.59674 7 5.89782 7 4.5V4.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M17 4.5V4.5C17 5.89782 17 6.59674 17.2284 7.14805C17.5328 7.88313 18.1169 8.46716 18.8519 8.77164C19.4033 9 20.1022 9 21.5 9V9"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M17 19.5V19.5C17 18.1022 17 17.4033 17.2284 16.8519C17.5328 16.1169 18.1169 15.5328 18.8519 15.2284C19.4033 15 20.1022 15 21.5 15V15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M7 19.5V19.5C7 18.1022 7 17.4033 6.77164 16.8519C6.46716 16.1169 5.88313 15.5328 5.14805 15.2284C4.59674 15 3.89782 15 2.5 15V15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M2 10.4V13.6C2 15.8402 2 16.9603 2.43597 17.816C2.81947 18.5686 3.43139 19.1805 4.18404 19.564C5.03969 20 6.15979 20 8.4 20H15.6C17.8402 20 18.9603 20 19.816 19.564C20.5686 19.1805 21.1805 18.5686 21.564 17.816C22 16.9603 22 15.8402 22 13.6V10.4C22 8.15979 22 7.03969 21.564 6.18404C21.1805 5.43139 20.5686 4.81947 19.816 4.43597C18.9603 4 17.8402 4 15.6 4L8.4 4C6.15979 4 5.03969 4 4.18404 4.43597C3.43139 4.81947 2.81947 5.43139 2.43597 6.18404C2 7.03969 2 8.15979 2 10.4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(LoansIcon);
