import React from 'react';

import FacebookIcon from '../../images/Facebook';
import InstagramIcon from '../../images/Instagram';
import PinterestIcon from '../../images/Pinterest';
import TwitterIcon from '../../images/Twitter';
import YoutubeIcon from '../../images/Youtube';

export interface ISocialLinksProps {
  className?: string;
}

export default function SocialLinks({ className }: ISocialLinksProps) {
  return (
    <div className={className}>
      <a
        href="https://www.facebook.com/thinksaveretire/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Facebook"
      >
        <FacebookIcon />
      </a>
      <a
        href="https://twitter.com/ThinkSaveRetire"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Twitter"
      >
        <TwitterIcon />
      </a>
      <a
        href="https://www.instagram.com/thinksaveretire/?hl=en"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Instagram"
      >
        <InstagramIcon />
      </a>
      <a
        href="https://www.youtube.com/@ThinkSaveRetire"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Youtube"
      >
        <YoutubeIcon />
      </a>
      <a
        href="https://www.pinterest.com/ThinkSaveRetire/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Pinterest"
      >
        <PinterestIcon />
      </a>
    </div>
  );
}
