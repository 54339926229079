import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const MenuButton = (props: IIconProps) => {
  const { width = 22, height = 15, className, onClick } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      viewBox="0 0 22 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="#111" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="square">
        <path d="M1 1h20M1 7.5h20M1 14h20" />
      </g>
    </svg>
  );
};

export default React.memo(MenuButton);
