import React, { useCallback, useEffect, useState } from 'react';

import CloseIcon from '../../images/Close';
import Humburger from '../../images/Humburger';
import Logo from '../../images/logo/Logo';
import { ISiteSettings } from '../common/Layout';
import ProgressBar from '../ProgressBar/ProgressBar';
import { SearchForm } from '../SearchForm/SearchForm';
import SideNav from '../SideNav/SideNav';
import SocialLinks from '../SocialLinks/SocialLinks';

import Links from './Links';

import style from './Header.scss';

import cn from 'classnames';
import { Link } from 'gatsby';

export interface IHeaderProps {
  className?: string;
  site: ISiteSettings;
  postTitle?: string;
  isLightTheme?: boolean;
  isDarkTheme?: boolean;
  isProgressShown?: boolean;
}

export default function Header({
  className,
  site,
  postTitle,
  isLightTheme = false,
  isDarkTheme = false,
  isProgressShown = false,
}: IHeaderProps) {
  const [isSidebarVisible, setSidebarVisibility] = useState(false);
  const [isSearchVisible, setSearchVisible] = useState(false);

  const showSidebarMenu = () => {
    if (!isSidebarVisible) {
      setSidebarVisibility(true);
    }
  };

  const handleSidenavClose = useCallback(() => {
    if (isSidebarVisible) {
      setSidebarVisibility(false);
    }
  }, [isSidebarVisible]);

  const handleSearchToggle = (isVisible: boolean) => {
    setSearchVisible(isVisible);
  };

  const [scrollY, setScrollY] = useState(0);
  const navbarRef = React.useRef<HTMLDivElement | null>(null);
  const prevScrollTopRef = React.useRef<number>(0);

  const logit = useCallback(() => {
    if (navbarRef.current) {
      const navbarHeight = navbarRef.current.offsetHeight;
      const currentScrollTop = window.pageYOffset;
      if (
        prevScrollTopRef.current < currentScrollTop &&
        currentScrollTop > navbarHeight + navbarHeight
      ) {
        navbarRef.current.classList.add(style.scrollDown);
      } else if (
        prevScrollTopRef.current > currentScrollTop &&
        !(currentScrollTop <= navbarHeight)
      ) {
        navbarRef.current.classList.remove(style.scrollDown);
      }
      prevScrollTopRef.current = currentScrollTop;
      let newScrollY = 0;
      if (currentScrollTop > 1 && currentScrollTop < 200) {
        newScrollY = 2;
      } else if (currentScrollTop >= 200) {
        newScrollY = 200;
      }
      if (newScrollY !== scrollY) {
        setScrollY(newScrollY);
      }
    }
  }, [scrollY]);

  useEffect(() => {
    window.addEventListener('scroll', logit);
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener('scroll', logit);
    };
  }, [logit]);

  return (
    <>
      <header
        ref={navbarRef}
        className={cn(className, style.header, {
          scrolled: scrollY > 1,
          [style.scrolled]: scrollY > 1,
          [style.active]: isSearchVisible,
          [style.light]: isLightTheme,
          [style.dark]: isDarkTheme,
        })}
      >
        {!isSearchVisible && (
          <div
            className={cn(style.postLGTitle, {
              [style.showPostLGTitle]: scrollY >= 200 && postTitle,
            })}
          >
            <div className="container">
              <div className="row">
                <h3>{postTitle}</h3>
              </div>
            </div>
          </div>
        )}
        <div className="row align-items-center">
          <div
            className={cn('col-auto d-lg-none', style.toggleButton, {
              ['d-none d-sm-block']: isSearchVisible,
            })}
          >
            <Humburger onClick={showSidebarMenu} aria-label="Open Side Nav" />
          </div>
          <div
            className={cn('col col-lg', style.logo, {
              [style.postTitleLogo]: postTitle,
              ['d-none d-lg-block']: isSearchVisible,
              [style.showTitleLogo]: scrollY >= 200 && postTitle,
            })}
          >
            <h3 className="d-lg-none">{postTitle}</h3>
            <div className={style.logoContainer}>
              <a href="/" aria-label="Home">
                <Logo className={style.animateLogo} />
              </a>
            </div>
          </div>
          <div
            className={cn(
              isSearchVisible ? 'd-none' : 'd-none d-lg-block col-lg-auto',
              style.links,
              {
                [style.postTitle]: postTitle,
                [style.showTitle]: scrollY >= 200 && postTitle,
              }
            )}
          >
            <Links className={style.parent} activeClassName={style.active} />
          </div>
          <div
            className={cn(
              style.searchIcon,
              ' ml-auto',
              isSearchVisible ? 'col col-sm-auto' : 'col-auto'
            )}
          >
            <SearchForm callback={handleSearchToggle} />
          </div>
        </div>
        {isProgressShown && <ProgressBar />}
      </header>
      <SideNav isOpen={isSidebarVisible} onClose={handleSidenavClose}>
        <button
          className={style.closeButton}
          onClick={handleSidenavClose}
          aria-label="Close Side Nav"
        >
          <CloseIcon />
        </button>
        <Links className={style.sideNavLinks} isSideNav activeClassName={style.active} />
        <div className={style.bottomLink}>
          <Link to="/terms-and-conditions/">Terms</Link>
          <Link to="/privacy-policy/">Privacy</Link>
        </div>
        <SocialLinks className={style.socialLinks} />
        <p className={style.copyright}>
          &copy; {new Date().getFullYear()} {site.title}.
        </p>
      </SideNav>
    </>
  );
}
