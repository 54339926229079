import React, { useEffect, useRef, useState } from 'react';

import CloseIcon from '../../images/Close';
import SearchIcon from '../../images/Search';
import { IInputOnChange, Input } from '../Inputs/Input';

import style from './SearchForm.scss';

import cn from 'classnames';
import { navigate } from 'gatsby';

interface ISearchFormProps {
  callback?: (isVisible: boolean) => void;
}

export const SearchForm = (props: ISearchFormProps) => {
  const { callback } = props;
  const [searchValue, setSearchValue] = useState('');
  const [isVisible, setVisible] = useState(false);
  const wrapperRef = useRef(null);

  const handleInputChange = (input: IInputOnChange) => {
    setSearchValue(input.value);
  };

  const handleSearchIconClick = () => {
    setVisible(!isVisible);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchValue) {
      setVisible(false);
      setImmediate(() => {
        setSearchValue('');
        navigate(`/search/?q=${encodeURIComponent(searchValue)}`);
      });
    }
  };

  useEffect(() => {
    if (callback) {
      if (isVisible) {
        callback(isVisible);
      } else {
        // if hiding search, wait css animation time before firing callback
        setTimeout(() => {
          callback(isVisible);
        }, 250);
      }
    }
  }, [isVisible]);

  const handleOutsideClick = (event: any) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as any).contains(event.target) &&
      !event.target.classList.contains('searchButton')
    ) {
      setVisible(false);
      setSearchValue('');
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <form action="#" onSubmit={handleSubmit} className={style.searchForm}>
      <button
        className={cn('searchButton', isVisible ? cn(style.visible, 'visible') : '')}
        type={isVisible ? 'button' : 'submit'}
        onClick={handleSearchIconClick}
        aria-label="search"
      >
        <SearchIcon className={style.searchIcon} />
      </button>
      <div className={cn(style.inputWrapper, { [style.isVisible]: !!isVisible })}>
        {isVisible ? (
          <div ref={wrapperRef}>
            <Input
              value={searchValue}
              name="search"
              type="search"
              className={cn(style.inputWrapper, style.isVisible)}
              autoComplete="off"
              onChange={handleInputChange}
              placeholder="Search"
              helperText="Required Field"
              isAutoFocus
            />
            <input
              type="submit"
              style={{ position: 'absolute', width: 0, height: 0, opacity: 0, padding: 0 }}
            />
          </div>
        ) : null}
      </div>
      {isVisible && (
        <button className={style.closeIcon} onClick={handleOutsideClick}>
          <CloseIcon />
        </button>
      )}
    </form>
  );
};
