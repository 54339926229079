import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export function formatPublishedAtDate(date: string) {
  const day = 24 * 60 * 60 * 1000;
  if (new Date().getTime() - new Date(date).getTime() >= day) {
    if (new Date().getFullYear() === new Date(date).getFullYear()) {
      return dayjs(date).format('MMM D');
    }
    return dayjs(date).format('MMM D, YYYY');
  }
  return dayjs(date).fromNow();
}

export function splitPostHtml(html: string, excerpt: string | null) {
  if (excerpt) {
    const maxCount = excerpt.replace(/\n/g, '').trim().length;
    let charCount = 0;
    let isInsideTag = false;
    let openTagCount = 0;
    let i: number = 0;

    while (charCount < maxCount || openTagCount > 0 || isInsideTag) {
      const char = html[i];
      const nextChar = html[i + 1];
      if (char === '<') {
        isInsideTag = true;
        if (nextChar === '/') {
          openTagCount -= 1;
        } else {
          openTagCount += 1;
        }
      } else if (char === '>') {
        isInsideTag = false;
      } else if (!isInsideTag) {
        charCount += 1;
      }
      i += 1;
    }

    return [html.slice(0, i), html.slice(i)];
  }

  return ['', html];
}

export function isEmail(str: string | null | undefined) {
  return !!str && /^[^@\s]+@[^\s]+\.[a-zA-Z]+$/.test(str) === true;
}

export function getCookie(name: string, cookie: string) {
  const pattern = `${name}=([^;]+)(?:;|$)`;
  const [, value] = cookie.match(new RegExp(pattern)) || [];
  return value;
}

export function optimizeContent(content: string) {
  return (
    content
      // lazy load images and iframes
      .replace(/(<(img)[^>]*)/gm, '$1 loading="lazy"')
      // .replace(/(<(iframe)[^>]*)src=/gm, '$1 loading="lazy" data-src=')

      // async scripts
      .replace(/(<script[^>]*)src=/gm, '$1data-src=')

      // delay outgrow
      .replace(
        /initIframe\(.*\)/gm,
        (initIframe) => `
        document.querySelector(
          '[data-src="//dyv6f9ner1ir9.cloudfront.net/assets/js/nloader.js"]'
        ).addEventListener('load', function() {
          ${initIframe}
        });`
      )

      // open external links in new tab
      .replace(
        /<a[\s]+href="(?!(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?(((?!go))[a-z0-9]*)([\-\.]*thinksaveretire+)\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)/gm,
        '<a target="_blank" rel="noopener" href="'
      )
  );
}
