import React from 'react';

import scss from './_.module.scss';

import cn from 'classnames';

export interface TModalProps {
  title?: string;
  subTitle?: string;
  className?: string;
  bodyClassName?: string;
  closeIconClassName?: string;
  children: React.ReactNode;
  onClose?: () => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  isFullScreen?: boolean;
  isAnimating?: boolean;
  canBeClosedByEsc?: boolean;
  canBeClosedByOutsideClick?: boolean;
  isCloseButtonShown?: boolean;
}

export class Modal extends React.Component<TModalProps> {
  static defaultProps = {
    closeIconClassName: '',
    isFullScreen: false,
    isAnimating: false,
    canBeClosedByEsc: true,
    canBeClosedByOutsideClick: true,
    isCloseButtonShown: false,
    className: '',
    bodyClassName: '',
    onKeyDown: (): any => new Object(),
    onClose: (): any => new Object(),
  };
  modal = React.createRef<HTMLDivElement>();
  modalBody = React.createRef<HTMLDivElement>();
  windowScrollTop = 0;
  initialBodyStyles: React.CSSProperties = {};

  componentDidMount(): any {
    this.positionDialog();
    // use browser's window rather than parent's one (like iframe's)
    // to measure viewport height unit
    const vh = window.innerHeight * 0.01;

    this.windowScrollTop = window.pageYOffset;

    if (document.documentElement) {
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    // To prevent scrolling under modal in case the app is embedded to iframe
    // set inline styles to website body rather than iframe's

    // But make sure we save already specified style properties to prevent break
    // of customer's website layout

    if (document.body) {
      this.initialBodyStyles = Array.from(document.body.style).reduce<React.CSSProperties>(
        (acc, s: any) => {
          return { ...acc, [s]: scss[s] };
        },
        {}
      );
      // Don't use 'cssText' to set all properties with 1 determine
      // It will override other inline styles in <body  e.g. we lost
      // background-color: transparent in body styles in MessengerWidgetWrapper iframe

      // Note: Order matters
      // If set position fixed goes 1st it will change body size and offsetWidth will be incorrect

      document.body.style.height = ''; // vh ? 'calc(var(--vh, 1vh) * 100)' : '100vh';
      document.body.style.width = '100%';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${this.windowScrollTop}px`;
      document.body.style.overflow = 'hidden';
    }

    window.addEventListener('resize', this.positionDialog);
    if (this.modal.current) {
      this.modal.current.addEventListener('click', this.handleOverlayClick);
    }
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(): any {
    this.positionDialog();
  }

  componentWillUnmount(): any {
    if (document && document.body) {
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.overflow = '';
      document.body.style.height = '';
      document.body.style.width = '';
    }

    // Restore body inline styles
    Object.entries(this.initialBodyStyles).forEach(([key, value]: any[]) => {
      document.body.style[key] = value;
    });
    // Restore scroll position after modal is closed.
    // Check that window.scroll is a funcion
    // On some customer websites scroll function overwritten with object by some
    // probably WP pluging and/or UI builder or js library.
    // On their websites calling .scroll() throws an error and crashes the app
    if (this.windowScrollTop && typeof window.scroll === 'function') {
      window.scroll(0, this.windowScrollTop);
    }

    window.removeEventListener('resize', this.positionDialog);
    if (this.modal.current) {
      this.modal.current.removeEventListener('click', this.handleOverlayClick);
    }
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleHeightChange = (): any => {
    this.positionDialog();
  };

  handleOverlayClick = (e: Event): any => {
    const { canBeClosedByOutsideClick, onClose = () => new Object() } = this.props;
    if (e.target === this.modal.current && canBeClosedByOutsideClick) {
      onClose();
    }
  };

  handleKeyDown = (e: KeyboardEvent): any => {
    const { canBeClosedByEsc, onKeyDown, onClose = () => new Object() } = this.props;
    if (e.key === 'Escape' && canBeClosedByEsc) {
      onClose();
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  positionDialog = (): any => {
    const { isFullScreen, isAnimating } = this.props;
    const { left = 0 } = this.modalBody.current
      ? this.modalBody.current.getBoundingClientRect()
      : {};

    const zoomCoef = isAnimating ? 1.1 : 1;

    if (!isFullScreen) {
      const margin = Math.min(left * zoomCoef, 100);
      if (this.modal.current) {
        this.modal.current.style.paddingTop = `${margin}px`;
        this.modal.current.style.paddingBottom = `${margin}px`;
      }
    } else if (this.modal.current && this.modalBody.current) {
      // Reset margins when modal changes its size to full screen.

      this.modal.current.style.paddingTop = '0px';
      this.modal.current.style.paddingBottom = '0px';
    }
  };

  render(): any {
    const {
      children,
      className,
      bodyClassName,
      closeIconClassName,
      onClose,
      isFullScreen,
      isCloseButtonShown,
    } = this.props;
    return (
      <div ref={this.modal} className={cn(className, scss.modal)}>
        <div
          ref={this.modalBody}
          className={cn(bodyClassName, scss.dialog, {
            [scss.fullScreen]: isFullScreen,
          })}
        >
          {isCloseButtonShown && (
            <button className={cn(closeIconClassName, scss.closeIcon)} onClick={onClose}>
              {/* <CloseIcon /> */}
            </button>
          )}
          {children}
        </div>
      </div>
    );
  }
}
