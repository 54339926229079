import React from 'react';

import Close from '../../../images/Close';
import coffee from '../../../images/Coffee.png';
import { AnimatedModal } from '../../Modal/index';

import SignUpForm from './';

import style from './Popup.scss';

const SUBSCRIBED_COOKIE_NAME = 'tsr_subscribed_at';

const isSubscribed =
  typeof document === 'undefined' ? false : document.cookie.includes(SUBSCRIBED_COOKIE_NAME);

const SignUpPopup = (props: {
  closeOnEscape?: boolean;
  showOnExitIntent?: boolean;
  timeout?: number;
}) => {
  const { closeOnEscape = true, showOnExitIntent = true, timeout = 2000 } = props;

  const [isShown, setIsShown] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState(false);

  const handleShow = () => {
    setIsShown(true);
    setIsMounted(true);
  };

  const handleClose = () => {
    localStorage.setItem('signup_popup_close_timeout', (new Date().getTime() + 172800).toString());

    setTimeout(() => {
      setIsShown(false);
    }, 300);
  };

  const handleMouseLeave = (event: any) => {
    const hiddenTimeoutDate = localStorage.getItem('signup_popup_close_timeout');

    const isOutOfWindow = event.clientY < 0;

    const isSubscribed = document.cookie.includes(SUBSCRIBED_COOKIE_NAME);

    if (
      // not already closed
      (!hiddenTimeoutDate ||
        (hiddenTimeoutDate &&
          new Date().getTime() - new Date(+hiddenTimeoutDate).getTime() >= 0)) &&
      isOutOfWindow &&
      !isSubscribed
    ) {
      handleShow();
    }
  };

  const handleEscapeKey = (event: KeyboardEvent) => {
    event = event || window.event;
    if (event.keyCode === 27) {
      handleClose();
    }
  };

  const handleSubmit = () => {
    setTimeout(() => {
      handleClose();
    }, 1000);
  };

  React.useEffect(() => {
    const hiddenTimeoutDate = localStorage.getItem('signup_popup_close_timeout');

    if (
      isSubscribed ||
      window?.location.pathname === '/subscribe/' ||
      window?.location.pathname === '/email-subscriber-survey/' ||
      window?.location.pathname === '/crediyfi-reviews/' ||
      window?.location.pathname === '/personal-loans-payday-loans/' ||
      window?.location.pathname === '/best-payday-loans-online/' ||
      window?.location.pathname === '/lendjet-review/' ||
      window?.location.pathname === '/amone-reviews/' ||
      window?.location.pathname === '/stats/'
    ) {
      setIsShown(false);
    } else if (
      !hiddenTimeoutDate ||
      (hiddenTimeoutDate && new Date().getTime() - new Date(+hiddenTimeoutDate).getTime() >= 0)
    ) {
      if (showOnExitIntent) {
        document.addEventListener('mouseout', handleMouseLeave);
      } else {
        const readyStateCheckInterval = setInterval(() => {
          if (document.readyState === 'complete') {
            clearInterval(readyStateCheckInterval);
            setTimeout(() => {
              handleShow();
            }, timeout);
          }
        }, 1000);
      }
    } else if (isShown) {
      setIsShown(false);
    }

    // Escape closes popup
    if (closeOnEscape) {
      document.addEventListener('onkeydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('mouseout', handleMouseLeave);
      document.removeEventListener('onkeydown', handleEscapeKey);
    };
  }, []);

  const tags = ['Popup', 'Exit Intent'];

  if (showOnExitIntent) tags.push('Exit Intent');

  if (!isMounted) return null;

  return (
    <AnimatedModal isShown={isShown} onClose={handleClose} bodyClassName={style.modalBody}>
      <div className={style.contentWrapper}>
        <img src={coffee} width="220px" height="220px"></img>
        <div className={style.content}>
          <h3>Wait! ✋</h3>
          <h3>Want even more tips for building wealth?</h3>
          <p>
            Subscribe to our newsletter for more on investing, financial tools, and escaping from
            the rat race.
          </p>
          <div className={style.form}>
            <SignUpForm callBack={handleSubmit} tags={tags}>
              <p>Thank You!</p>
            </SignUpForm>
          </div>
        </div>
        <span className={style.close} onClick={handleClose}>
          <Close width={12} height={12} />
        </span>
      </div>
    </AnimatedModal>
  );
};

export default SignUpPopup;
