import React from 'react';

import featuredLg from '../../images/Home/featuredLg.svg';
import { lazyLoading } from '../../utils/lazyLoading';
import { ISiteSettings } from '../common/Layout';
import SocialLinks from '../SocialLinks/SocialLinks';

import FooterTags from './FooterTags';

import style from './Footer.scss';

import cn from 'classnames';
import { graphql, StaticQuery } from 'gatsby';
import { Link } from 'gatsby';

export interface IFooterProps {
  className?: string;
  site: ISiteSettings;
  data: {
    allGhostTag: {
      edges: {
        node: {
          name: string;
          slug: string;
        };
      }[];
    };
  };
}

function Footer({ className, site, data }: IFooterProps) {
  const tags = data.allGhostTag.edges;

  React.useEffect(() => {
    lazyLoading();
  }, []);

  return (
    <footer className={cn(className, style.footer)}>
      <div className={style.featured}>
        <div className={style.featuredImageWrapper}>
          <img
            src={featuredLg}
            loading="lazy"
            alt="As featured in CNBC Forbes Business Insider ABC and Market Watch"
            height="56px"
          />
        </div>
      </div>
      <div className="container">
        <h6>Discover what matters to you</h6>
        <FooterTags tags={tags} />
      </div>
      <div className={style.divider}></div>
      <div className="container">
        <div className={style.footerMiddle}>
          <Link className="d-none d-md-block" to="/subscribe/">
            Subscribe
          </Link>
          <Link className="d-none d-md-block" to="/advertise/">
            Advertise
          </Link>
          <Link className="d-none d-md-block" to="/disclosure/">
            Disclosure
          </Link>
          <Link className="d-none d-md-block" to="/contact/">
            Contact
          </Link>
          <Link className="d-none d-md-block" to="/archives/">
            Post Archives
          </Link>
          <Link className="d-none d-md-block" to="/about/">
            About
          </Link>
          <Link to="/terms-and-conditions/">Terms</Link>
          <Link to="/privacy-policy/">Privacy</Link>
          {/* <Link className="d-none d-md-block" to="/media-rap-sheet/">
            Media Rap Sheet
          </Link>
          <Link to="/stats/">Stats</Link>
          <Link className="d-none d-md-block" to="/write-partner/">
            Write / Partner
          </Link>
          <Link className="d-none d-md-block" to="/sponsored-posts/">
            Sponsored Posts
          </Link>
          {/* <Link className="d-none d-md-block" to="/authors/">
            Authors
          </Link> */}
        </div>
        <SocialLinks className={style.socialLinks} />
        <div className={style.footerBottomSection}>
          {/* <p>
              This page may include affiliate links. We may get paid when you click on a link and
              buy a product, at no extra cost to you. <br />
              <GatsbyLink to="/disclosure/">Click here here to learn more.</GatsbyLink>
              <br />
              <br />
            </p> */}
          <p>
            &copy; 2018—{new Date().getFullYear()} {site.title}
            <br /> All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

const FooterQuery = (props: any) => {
  const renderFooter = (data: any) => <Footer data={data} {...props} />;
  return (
    <StaticQuery
      query={graphql`
        query {
          allGhostTag(sort: { fields: postCount, order: DESC }) {
            edges {
              node {
                name
                slug
              }
            }
          }
        }
      `}
      render={renderFooter}
    />
  );
};

export default FooterQuery;
