import React from 'react';

interface IIconProps {
  className?: string;
}

const Logo = (props: IIconProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="241"
      height="25"
      viewBox="0 0 241 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="logo-wrapper">
        <g className="dot" id="Dot-3">
          <path
            d="M238.782 24.32C237.63 24.32 236.766 23.456 236.766 22.304C236.766 21.184 237.63 20.32 238.782 20.32C239.934 20.32 240.798 21.184 240.798 22.304C240.798 23.456 239.934 24.32 238.782 24.32Z"
            fill="currentColor"
            style={{
              opacity: 0.5,
            }}
          />
        </g>
        <g className="animate" id="E-third">
          <path
            d="M226.392 24.352C221.368 24.352 218.04 20.672 218.04 15.584C218.04 9.79201 221.784 6.65601 226.072 6.65601C230.744 6.65601 233.752 9.79201 233.848 15.232V15.968H220.696C220.856 19.584 222.968 22.048 226.392 22.048C228.76 22.048 230.488 20.864 231.192 18.656H233.784C233.016 22.144 230.264 24.352 226.392 24.352ZM220.856 13.76H231.064C230.584 10.784 228.792 8.96001 226.072 8.96001C223.384 8.96001 221.4 10.72 220.856 13.76Z"
            fill="currentColor"
          />
        </g>
        <g className="animate" id="R-second">
          <path
            d="M210.404 24H207.748V7.03997H209.86L210.18 10.272C211.268 8.09597 213.252 6.94397 216.068 6.94397H216.644V9.56797H216.068C212.484 9.56797 210.404 11.616 210.404 15.104V24Z"
            fill="currentColor"
          />
        </g>
        <g className="animate" id="I-second">
          <path
            d="M202.092 4.19198C200.972 4.19198 200.108 3.32798 200.108 2.20798C200.108 1.08798 200.972 0.255981 202.092 0.255981C203.212 0.255981 204.044 1.08798 204.044 2.20798C204.044 3.32798 203.212 4.19198 202.092 4.19198ZM203.404 24H200.748V7.03998H203.404V24Z"
            fill="currentColor"
          />
        </g>
        <g className="animate" id="T-second">
          <path
            d="M197.33 24H195.442C192.146 24 190.386 22.304 190.386 19.168V9.27999H187.25V7.03999H190.386V1.24799H193.042V7.03999H197.618V9.27999H193.042V19.168C193.042 20.864 193.874 21.76 195.442 21.76H197.33V24Z"
            fill="currentColor"
          />
        </g>
        <g className="animate" id="E-second">
          <path
            d="M177.986 24.352C172.962 24.352 169.634 20.672 169.634 15.584C169.634 9.79201 173.378 6.65601 177.666 6.65601C182.338 6.65601 185.346 9.79201 185.442 15.232V15.968H172.29C172.45 19.584 174.562 22.048 177.986 22.048C180.354 22.048 182.082 20.864 182.786 18.656H185.378C184.61 22.144 181.858 24.352 177.986 24.352ZM172.45 13.76H182.658C182.178 10.784 180.386 8.96001 177.666 8.96001C174.978 8.96001 172.994 10.72 172.45 13.76Z"
            fill="currentColor"
          />
        </g>
        <g id="R-first">
          <path
            d="M161.998 24H159.342V7.03997H161.454L161.774 10.272C162.862 8.09597 164.846 6.94397 167.662 6.94397H168.238V9.56797H167.662C164.078 9.56797 161.998 11.616 161.998 15.104V24Z"
            fill="currentColor"
          />
        </g>
        <g className="dot animate" id="Dot-2">
          <path
            d="M153.563 24.32C152.411 24.32 151.547 23.456 151.547 22.304C151.547 21.184 152.411 20.32 153.563 20.32C154.715 20.32 155.579 21.184 155.579 22.304C155.579 23.456 154.715 24.32 153.563 24.32Z"
            fill="currentColor"
            style={{
              opacity: 0.5,
            }}
          />
        </g>
        <g className="animate" id="E-first">
          <path
            d="M141.173 24.352C136.149 24.352 132.821 20.672 132.821 15.584C132.821 9.79201 136.565 6.65601 140.853 6.65601C145.525 6.65601 148.533 9.79201 148.629 15.232V15.968H135.477C135.637 19.584 137.749 22.048 141.173 22.048C143.541 22.048 145.269 20.864 145.973 18.656H148.565C147.797 22.144 145.045 24.352 141.173 24.352ZM135.637 13.76H145.845C145.365 10.784 143.573 8.96001 140.853 8.96001C138.165 8.96001 136.181 10.72 135.637 13.76Z"
            fill="currentColor"
          />
        </g>
        <g className="animate" id="V">
          <path
            d="M124.412 24H121.628L114.556 7.03998H117.436L123.036 20.896L128.604 7.03998H131.484L124.412 24Z"
            fill="currentColor"
          />
        </g>
        <g className="animate" id="A">
          <path
            d="M103.783 24.256C99.9425 24.256 97.4785 22.272 97.4785 19.168C97.4785 15.264 101.255 14.528 104.647 14.176C107.239 13.92 109.479 13.952 109.511 12.576C109.479 10.432 107.719 8.92801 105.159 8.92801C102.695 8.92801 100.775 10.304 100.487 12.32H97.8625C98.1825 8.99201 101.191 6.65601 105.223 6.65601C109.415 6.65601 112.103 9.15201 112.103 12.736V20.992C112.103 21.536 112.391 21.824 112.967 21.824H113.927V24H112.423C110.599 24 109.639 23.168 109.639 21.6V21.088C108.647 23.04 106.727 24.256 103.783 24.256ZM104.167 21.984C107.335 21.984 109.511 19.968 109.511 17.152V14.944C108.775 15.936 106.887 16.128 104.807 16.352C102.471 16.608 100.135 16.928 100.135 19.104C100.135 20.864 101.735 21.984 104.167 21.984Z"
            fill="currentColor"
          />
        </g>
        <g id="S">
          <path
            d="M88.5216 24.352C84.4896 24.352 81.5136 22.24 81.2896 18.656H83.8816C84.0736 20.864 86.0896 22.08 88.6496 22.08C91.0496 22.08 92.2016 21.152 92.2016 19.456C92.2016 17.856 90.9536 17.504 89.1936 16.896L86.7296 16.064C84.2336 15.232 81.7375 14.464 81.7375 11.392C81.7375 8.51201 83.9776 6.65601 87.6896 6.65601C91.4336 6.65601 94.0256 8.67201 94.4416 12.448H91.8495C91.5615 10.08 89.8976 8.92801 87.5936 8.92801C85.5136 8.92801 84.2976 9.85601 84.2976 11.328C84.2976 13.088 86.1536 13.44 87.5616 13.92L89.8656 14.688C92.9696 15.712 94.8256 16.768 94.8256 19.584C94.8256 22.56 92.6496 24.352 88.5216 24.352Z"
            fill="currentColor"
          />
        </g>
        <g className="dot animate" id="Dot-1">
          <path
            d="M76.4066 24.32C75.2546 24.32 74.3906 23.456 74.3906 22.304C74.3906 21.184 75.2546 20.32 76.4066 20.32C77.5586 20.32 78.4226 21.184 78.4226 22.304C78.4226 23.456 77.5586 24.32 76.4066 24.32Z"
            fill="currentColor"
            style={{
              opacity: 0.5,
            }}
          />
        </g>
        <g className="animate" id="K">
          <path
            d="M60.344 24H57.688V0H60.344V15.392L68.6 7.04H71.8L65.688 13.216L72.728 24H69.624L63.864 15.072L60.344 18.624V24Z"
            fill="currentColor"
          />
        </g>
        <g className="animate" id="N">
          <path
            d="M41.7478 24H39.0918V7.04001H41.2038L41.4598 9.63201C42.6118 7.84001 44.8198 6.65601 47.3478 6.65601C50.9958 6.65601 53.4278 9.02401 53.4278 12.768V24H50.7718V12.864C50.7718 10.496 49.2358 8.99201 46.6758 8.99201C43.6358 8.99201 41.7798 11.104 41.7478 13.696V24Z"
            fill="currentColor"
          />
        </g>
        <g className="animate" id="I-first">
          <path
            d="M33.4357 4.19198C32.3157 4.19198 31.4517 3.32798 31.4517 2.20798C31.4517 1.08798 32.3157 0.255981 33.4357 0.255981C34.5557 0.255981 35.3877 1.08798 35.3877 2.20798C35.3877 3.32798 34.5557 4.19198 33.4357 4.19198ZM34.7477 24H32.0917V7.03998H34.7477V24Z"
            fill="currentColor"
          />
        </g>
        <g className="animate" id="H">
          <path
            d="M16.2502 24H13.5942V0H16.2502V9.216C17.4662 7.68 19.5462 6.656 21.8502 6.656C25.4982 6.656 27.9302 9.024 27.9302 12.768V24H25.2742V12.864C25.2742 10.496 23.7382 8.992 21.3382 8.992C18.1382 8.992 16.2822 11.104 16.2502 13.696V24Z"
            fill="currentColor"
          />
        </g>
        <g id="T">
          <path
            d="M10.08 24H8.192C4.896 24 3.136 22.304 3.136 19.168V9.27999H0V7.03999H3.136V1.24799H5.792V7.03999H10.368V9.27999H5.792V19.168C5.792 20.864 6.624 21.76 8.192 21.76H10.08V24Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

export default React.memo(Logo);
