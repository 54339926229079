import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const EarnMoneyIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66675 14.2222C8.66675 16.0634 11.6512 17.5556 15.3334 17.5556C19.0156 17.5556 22.0001 16.0634 22.0001 14.2222M8.66675 14.2222C8.66675 12.3811 11.6512 10.8889 15.3334 10.8889C19.0156 10.8889 22.0001 12.3811 22.0001 14.2222M8.66675 14.2222V18.6667C8.66675 20.5067 11.6512 22 15.3334 22C19.0156 22 22.0001 20.5067 22.0001 18.6667V14.2222"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M2 5.33333C2 6.52444 3.27111 7.62444 5.33333 8.22C7.39556 8.81556 9.93778 8.81556 12 8.22C14.0622 7.62444 15.3333 6.52444 15.3333 5.33333C15.3333 4.14222 14.0622 3.04222 12 2.44667C9.93778 1.85111 7.39556 1.85111 5.33333 2.44667C3.27111 3.04222 2 4.14222 2 5.33333ZM2 5.33333V16.4444C2 17.4311 2.85778 18.0556 4.22222 18.6667M2 10.8889C2 11.8756 2.85778 12.5 4.22222 13.1111"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(EarnMoneyIcon);
