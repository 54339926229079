import React, { PureComponent, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { Modal, TModalProps } from './layout';

import { CSSTransition } from 'react-transition-group';

const ANIMATION_DURATION = 300;

export interface IProps extends TModalProps {
  children: ReactNode;
  isShown: boolean;
  zIndex?: number;
}

interface IState {
  isShown: boolean;
  isAnimating: boolean;
  isMounted: boolean;
}

export class AnimatedModal extends PureComponent<IProps, IState> {
  static defaultProps = {
    zIndex: 10000,
  };

  private animatingTimeout?: NodeJS.Timeout;
  private inputField: React.RefObject<HTMLElement | undefined>;
  mainElList: HTMLElement[];

  static getDerivedStateFromProps(
    nextProps: IProps,
    prevState: IState
  ): { isShown: boolean } | null {
    if (!prevState.isMounted) {
      return null;
    }

    const { isShown } = nextProps;
    if (isShown && !prevState.isShown) {
      // as soon as <body> get hight: 100vh and overflow hidden
      // the vertical scrollbar disapears what makes content to jump left on 15px
      // (or depends on browser's scrollbar width);
      // applying window width exclude scrollbar width keeps content placed
      const { width } = window.getComputedStyle(document.body);

      if (document.body) {
        document.body.style.width = width;
      }

      return { isShown };
    }

    return null;
  }
  modal?: HTMLDivElement;

  constructor(props: IProps) {
    super(props);

    this.inputField = React.createRef();

    this.state = {
      isAnimating: props.isShown,
      isShown: props.isShown,
      isMounted: false,
    };
  }

  componentDidMount(): void {
    this.modal = document.createElement('div');
    // Despite of we specified zIndex in defaultProps
    // flowJS insists that zIndex mthis.modal && ight be undefined and
    // cannot coerce the variable to string. so added `|| 10000`
    this.modal.style.cssText = `position:absolute;z-index:${this.props.zIndex || 10000};`;

    this.mainElList = [
      document.getElementsByTagName('header')[0],
      document.getElementsByTagName('main')[0],
      document.getElementsByTagName('footer')[0],
    ];

    if (this.modal && document.body) {
      document.body.appendChild(this.modal);
    }

    if (!this.state.isMounted) {
      this.setState({ isMounted: true });
    }

    if (this.props.isShown) {
      if (this.mainElList.length) {
        this.mainElList.map((el) => {
          if (el) {
            el.style.transition = `filter ${ANIMATION_DURATION}ms`;
            el.style.filter = 'blur(4px)';
          }
        });
      }
      this.animatingTimeout = setTimeout(
        () => this.setState({ isAnimating: false }),
        ANIMATION_DURATION
      );
    }
  }

  componentDidUpdate(prevProps: IProps): void {
    const { isShown } = this.props;

    if (isShown !== prevProps.isShown && this.mainElList.length) {
      this.mainElList.map((el) => {
        if (el) {
          el.style.transition = `filter ${ANIMATION_DURATION}ms`;
          el.style.filter = `blur(${isShown ? 4 : 0}px)`;
        }
      });
    }

    if (isShown && !prevProps.isShown) {
      this.setState({ isAnimating: true });
      setTimeout(() => this.setState({ isAnimating: false }), ANIMATION_DURATION);
    } else if (!isShown && prevProps.isShown) {
      // waiting until animation is done
      setTimeout(() => {
        if (document.body) {
          document.body.style.width = '';
        }
      }, ANIMATION_DURATION);
    }
  }

  componentWillUnmount(): void {
    if (this.modal && document.body) {
      document.body.removeChild(this.modal);
    }
    if (this.animatingTimeout) clearTimeout(this.animatingTimeout);
  }

  render(): JSX.Element | null {
    const { isShown, ...restProps } = this.props;
    const { isAnimating, isMounted } = this.state;

    if (!isMounted || !this.modal) {
      return null;
    }

    const AnimatedModal = (
      <CSSTransition
        classNames="modal"
        in={isShown}
        timeout={ANIMATION_DURATION}
        unmountOnExit
        nodeRef={this.inputField}
      >
        <Modal {...restProps} isAnimating={isAnimating} />
      </CSSTransition>
    );

    return createPortal(AnimatedModal, this.modal);
  }
}
