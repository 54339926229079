import React from 'react';

import style from './Footer.scss';

import { Link } from 'gatsby';

export interface IFooterProps {
  tags: {
    node: {
      name: string;
      slug: string;
    };
  }[];
}

export default function FooterTags({ tags }: IFooterProps) {
  return (
    <div className={style.footerTags}>
      {tags.map((tag, index: number) => {
        const { name, slug } = tag.node;
        if (index < 8) {
          return (
            <Link to={`/tag/${slug}/`} key={index}>
              {name}
            </Link>
          );
        }
      })}
    </div>
  );
}
